import React from 'react';

function Work() {
	return (
		<section>
		<div id="main">
			<h2 className='alphabet-title'>Work</h2>
			<section>
				<h2>簡単な職歴</h2>
				<p>
					2008年4月～2019年4月、一般企業に就業。<br />
					内2回、会社が倒産して転籍、2018年4月に退職。<br />
					2019年5月に開業。
				</p>
				<p className='text_red'>スキルはTOPをご確認ください。</p>
			</section>
{/* 			<section>
				<h2>職務経歴</h2>
				<dl className='jobcareer_list'>
					<dt>電化製品製造会社</dt>
					<dd className='job_period'>2008年5月～20012年1月</dd>
					<dd className='job_description'>
						<ul>
							<li>テスト業務</li>
							<li>テスト手順書作成</li>
							<li>データ整備</li>
						</ul>
					</dd>
				</dl>
				<dl className='jobcareer_list'>
					<dt>通信販売コールセンターシステム</dt>
					<dd className='job_period'>2012年2月～2014年4月</dd>
					<dd className='job_description'>
						<ul>
							<li>PMO業務（5チーム50人規模）</li>
							<li>SEサポート</li>
							<li>チーム用事務システム構築</li>
						</ul>
					</dd>
					<dd>スキル：ExcelVBA、AccessVBA、Oracle</dd>
				</dl>
				<dl className='jobcareer_list'>
					<dt>自動車製造社</dt>
					<dd className='job_period'>2014年5月～2016年4月</dd>
					<dd className='job_description'>
						<ul>
							<li>コンテンツCMS移行</li>
							<li>コンテンツ制作</li>
							<li>キャンペーンページ管理・運用</li>
							<li>CMSフロント改修</li>
							<li>CMS手順書、資料作成</li>
						</ul>
					</dd>
					<dd>スキル：HTML、CSS、JS、サブディレクション</dd>
				</dl>
				<dl className='jobcareer_list'>
					<dt>医療施設予約システム運営会社</dt>
					<dd className='job_period'>2016年5月～2019年3月</dd>
					<dd className='job_description'>
						<ul>
							<li>Wordpress構築</li>
							<li>コラムサイト運用・保守</li>
							<li>ABテスト実施</li>
							<li>新規サイト制作</li>
						</ul>
					</dd>
					<dd>スキル：HTML、CSS（Sass）、JS、MySQL、ディレクター補佐</dd>
				</dl>
				<dl className='jobcareer_list'>
					<dt>株式会社エンパワーヘルスケア様</dt>
					<dd className='job_period'>2019年5月～2020年4月、2021年7月～2023年2月</dd>
					<dd className='job_description'>
						<ul>
							<li>Wordpress構築</li>
							<li>コラムサイト運用・保守</li>
							<li>ABテスト実施</li>
						</ul>
					</dd>
					<dd>スキル：HTML、CSS（Sass）、JS、MySQL、ディレクター補佐</dd>
				</dl>
			</section> */}
		</div>
	</section>
	);
}

export default Work
