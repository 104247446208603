import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
	return (
		<header>
			<div id="header-area">
				<h1 className="alphabet-title"><Link to="Home">T.MIKAMI</Link></h1>
				<nav>
					<ul id="header-menu">
						<li><a href="#">BLOG</a></li>
						<li><Link to="Activity">Activity</Link></li>
						<li><Link to="Work">Work</Link></li>
					</ul>
				</nav>
			</div>
		</header>
	);
}

export default Header;
