import './App.css';
import Header from './compornents/Header';
import Footer from './compornents/Footer';
import Home from './compornents/Home';
import Activity from './compornents/Activity';
import Work from './compornents/Work';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useRef, useEffect } from "react";
import Rellax from "rellax";

function App() {

  const rellax = new Rellax('.bgAnimation');

  return (
    <Router>
    <div className="bgArea">
      <span id="circle1" className="bgAnimation yellow-circle" data-rellax-speed="-3"></span>
      <span id="square1" className="bgAnimation blue-dotted-square" data-rellax-speed="-7"></span>
      <span id="circle2" className="bgAnimation yellow-circle" data-rellax-speed="-10"></span>
      <span id="square2" className="bgAnimation blue-dotted-square" data-rellax-speed="-10"></span>
    </div>
    <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={ <Home /> }/>
          <Route path="/Home" element={ <Home /> }/>
          <Route path="/Activity" element={ <Activity /> }/>
          <Route path="/Work" element={ <Work /> }/>
        </Routes>
        <Footer />
    </div>
    </Router>
  );
}

export default App;
