import React from 'react';
import SassImg from '../img/icons8-sass-384.png';
import WpImg from '../img/wordpress_icon.png'

function Home() {
	return (
		<section>
			<div id="main">
				<h2 className="alphabet-title">T.MIKAMI</h2>
				<p id="profile">コーディングをメインにWeb制作・DTP・バナーデザイン。<br />
				VBA使えるMicrosoftOfficeスペシャリスト。<br />
				BtoCサイト制作、CMSのフロント制作、Wordpressフルスクラッチ経験あります。</p>	
				<section>
					<h2 className="alphabet-title">SKILL</h2>
					<ul className="list-card">
						<li className="list-card-sheet" id="skill-html">HTML<br />CSS</li>
						<li className="list-card-sheet" id="skill-sass"><img alt="Sass" src={SassImg} /></li>
						<li className="list-card-sheet" id="skill-js">JS<br />jQuery</li>
						<li className="list-card-sheet" id="skill-adobe">Adobe<br />Ps・Ai</li>
						<li className="list-card-sheet" id="skill-wp"><img alt="Wordpress" src={WpImg} /></li>
						<li className="list-card-sheet" id="skill-msoffice">Office<span>Excel VBA</span></li>
					</ul>
				</section>
			</div>
		</section>
	);
}

export default Home
