import React from 'react';

function Activity() {
	return (
		<section>
			<div id="main">
				<h2 className='alphabet-title'>Activity</h2>
				<section>
					<h2>デザイン</h2>
					<p>
						DTP・バナーをデザイン、および改修も承っております。
					</p>
				</section>
				<section>
					<h2>Excel教室</h2>
					<p>
						MOS取得、基礎的操作などについて不定期で教室を実施。<br />
						オンラインOK、近隣の方は直接ご指導も可能です。
					</p>
					<h3>過去のイベント</h3>
				</section>
			</div>
		</section>
	);
}

export default Activity
