import React from 'react';

function Footer() {
	return (
		<footer>
			<div id="footer-area">
				<p className='footer_name'>三上 高葉</p>
				<div className='footer_infomation'>
					<p>m.takaha.b@gmail.com</p>
					<p>Instagram : mikami_shachi</p>
				</div>
			</div>
				<p id="footer_copy">©T.Mikami  2023/02</p>
		</footer>
	);
}

export default Footer;
